import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Galerie</h2>
          <p>
            Das Handwerk des Baum-Service bei der Arbeit
          </p>
        </div>
        <div className='row'>
          <div className='portfolio-items'>
            {props.data
              ? props.data.map((d, i) => {
                  return d.largeImage && d.smallImage ? (
                    <div
                      key={`${d.title}-${i}`}
                      className='col-sm-6 col-md-4 col-lg-4'
                    >
                      <Image
                        title={d.title}
                        largeImage={d.largeImage}
                        smallImage={d.smallImage}
                      />
                    </div>
                  ) : null;
                })
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
